<template>
  <div class="page">
    <div class="page-header">政务发文监测</div>
    <div class="page-card">
      <PlatformCom ref="platform_type" :desc="'platform_type'"></PlatformCom>
      <PlatformCom ref="body_type" :zwfw="true" :desc="'body_type'"></PlatformCom>
      <el-row :gutter="20">
        <el-col :span="20" class="selectBox-group-left">
          <div class="flex-c m-t-20">
            <el-input placeholder="请输入搜索关键字" v-model="searchText" style="width:21rem" class="input-with-select">
              <el-select v-model="selSearchVal" style="width:7rem" slot="prepend" placeholder="请选择">
                <el-option label="账号名称" value="1"></el-option>
                <el-option label="域名/URL(网址)" value="2"></el-option>
              </el-select>
            </el-input>
            <span class="m-l-16 m-r-16">未更新天数</span>
            <el-input-number style="width:4rem" v-model="minDay" :min="1" :controls="false"></el-input-number>
            <span>~</span>
            <el-input-number style="width:4rem" v-model="maxDay" :min="1" :controls="false"></el-input-number>
            <span class="m-l-8">天</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="selectBox-group-btn">
            <div class="queryBtn" @click="search">查询</div>
            <div class="resetBtn" @click="reset">重置</div>
            <div class="exportBtn" @click="exportDakai">
              <img src="@/assets/img/zhang/export.png" alt="" /><span>数据导出</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="page-card" v-loading="loading">
      <!-- 表格 -->
      <el-table  class="m-t" stripe :data="tableData" :header-cell-style="{
          background: '#F6F7FA',
          color: '#333',
        }" style="
          width: 100%;
          border: 1px solid #e4e6ec;
          border-bottom: none;
        ">
        <el-table-column label="序号" :index="indexMethod" type="index" width="80"></el-table-column>
        <el-table-column prop="platformTypeName" label="媒体类型"></el-table-column>
        <el-table-column prop="accountName" label="账号名称" show-overflow-tooltip width="250"></el-table-column>
        <el-table-column label="网站域名/主页地址" show-overflow-tooltip width="550">
          <template slot-scope="scope">
            <span style="color: #2E59EC" class="cur" @click="openUrl(scope.row.homeUrl)">{{ scope.row.homeUrl }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bodyTypeName" label="主体类型"></el-table-column>
        <el-table-column prop="latestDataTime" label="最后发文时间" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.latestDataTime?scope.row.latestDataTime:'无' }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <el-popover ref="myPop" placement="bottom" style="width: 30rem;"  :offset="-50" trigger="click">
              <div slot="reference">
                <span>发文数</span>
                <i class="el-icon-caret-bottom"></i>
              </div>
              <div class="popover-content">
                <div class="flex-b-c p-l-20 p-r-20">
                  <div class="sel-day" @click="fawenBtnGroup(item.value)" v-for="item in timeBtnGroup" :class="{'sel-day-active':selTimeBtn==item.value}"
                       :key="item.value">{{ item.label }}</div>
                  <!-- <div class="sel-day sel-day-active">全部</div>
                  <div class="sel-day">近7天</div>
                  <div class="sel-day">近30天</div>
                  <div class="sel-day">近3个月</div>
                  <div class="sel-day">近半年</div> -->
                </div>
                <div class="flex-c m-t-24 p-l-20 p-r-20">
                  <span class="">日期选择</span>
                  <el-date-picker class="m-l" v-model="timeArr" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
                                  end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
                </div>
                <el-divider></el-divider>
                <div class="flex-c-c">
                  <el-button @click="fawenNoTime">取 消 </el-button>
                  <el-button @click="fawenTimeBtn" type="primary">确 定</el-button>
                </div>
              </div>
            </el-popover>

          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.contentCount?scope.row.contentCount:0 }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="未更新天数">
          <template slot-scope="scope">
            <span>{{ scope.row.notUpdatedDays?scope.row.notUpdatedDays:'无' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination v-if="showFenye" :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="page.total" @SizeChange="SizeChange"
                  @CurrentChange="CurrentChange" :layout="'total, sizes, prev, pager, next'"/>
    </div>
    <!-- 导出弹出框 -->
    <exportDialog ref="exportDialog"></exportDialog>
  </div>
</template>

<script>
import PlatformCom from "@/components/dressing/PlatformCom.vue"; //  筛选框 全选多选
import Pagination from "@/components/pagination";
import Api from '@/api/zwfwMonitor/index'
import { exportExcel } from "../../api/platformaccount/index";
import exportDialog from "../../components/exportDialog.vue";
export default {
  components: {
    PlatformCom,
    Pagination,
    exportDialog
  },
  data() {
    return {
      showFenye: false,//删除分页最后一页引起的连锁反应
      searchText: '',
      selSearchVal: '1',
      timeArr: [],
      minDay: undefined,
      maxDay: undefined,
      loading: false,
      tableData: [
      ],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      timeBtnGroup: [
        { label: "全部", value: 1 },
        { label: "近7天", value: 2 },
        { label: "近30天", value: 3 },
        { label: "近3个月", value: 4 },
        { label: "近半年", value: 5 }
      ],
      selTimeBtn: 1,//表头时间框的选择
    };
  },
  mounted() {
    this.search()
    this.showFenye = true
  },
  methods: {
    search() {
      this.page.pageNo = 1
      this.getList()
    },
    async getList(isFenye = false) {
      this.loading = true

      let data = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        queryGov: true
      }
      if (isFenye) {
        data.queryGov = true
      }
      let platformTypeList = this.$refs.platform_type.formObj.checkedform.map(
        (v) => v.value
      )
      let bodyTypeList = this.$refs.body_type.formObj.checkedform.map((v) => v.value) //主体类型
      if (platformTypeList.length) data.platformTypeList = platformTypeList
      if (bodyTypeList.length) data.bodyTypeList = bodyTypeList
      if (this.timeArr.length) {
        data.publishDateStart = this.timeArr[0]
        data.publishDateEnd = this.timeArr[1]
      }
      
      if (this.minDay) data.notUpdatedDaysStart = this.minDay
      if (this.maxDay) data.notUpdatedDaysEnd = this.maxDay
      if ((this.minDay && this.maxDay) && (data.notUpdatedDaysStart > data.notUpdatedDaysEnd)) {
        data.notUpdatedDaysStart = this.maxDay
        data.notUpdatedDaysEnd = this.minDay
      }
      if (this.selSearchVal == 1 && this.searchText) data.accountName = this.searchText
      if (this.selSearchVal == 2 && this.searchText) data.domainName = this.searchText

      let res = await Api.getList(data)
      if (res?.data?.code == 200) {
        this.tableData = res.data.data.list
        this.page.total = res.data.data.totalCount/1
        this.loading = false
      }
      setTimeout(() => {
        this.deleteLastLi()//删除分页最后一页
      }, 300);
    },
    reset() {
      this.$refs.platform_type.resetCom(); // 平台类型
      this.$refs.body_type.resetCom(); // 主体类型
      this.timeArr = []
      this.searchText = ''
      this.selSearchVal = '1'
      this.selTimeBtn = 1
      this.maxDay = undefined
      this.minDay = undefined
      this.search()
    },
    openUrl(url) {
      let fixedUrl = this.fixUrl(url)
      window.open(fixedUrl, '_blank')
    },
    fixUrl(url) {
        if (!/^https?:\/\//.test(url)) {
            return 'http://' + url; // 默认加上 http://
        }
        return url;
    },
    exportDakai() {
      const { page } = this;
      let obj = {
        platformTypeList: this.$refs.platform_type.formObj.checkedform.map(
          (v) => v.value
        ),
        bodyTypeList: this.$refs.body_type.formObj.checkedform.map((v) => v.value),
        publishDateStart: this.timeArr ? this.timeArr[0] : undefined,
        publishDateEnd: this.timeArr ? this.timeArr[1] : undefined,
        notUpdatedDaysStart: this.minDay,
        notUpdatedDaysEnd: this.maxDay,
        queryGov: true,
        
      }
      if (this.selSearchVal == 1 && this.searchText) obj.accountName = this.searchText
      if (this.selSearchVal == 2 && this.searchText) obj.domainName = this.searchText
      this.exportFN({ ...obj, ...page });
    },
    // 导出
    async exportFN(obj) {

      // if (obj.platformTypeList?.length != 1) {
      //   this.$message.warning('目前只支持单平台导出,请重新操作')
      //   return
      // }
      //                        请求函数，参数配置,组件配置                           后面的1代表新的导出
      await this.$main.exportFN(exportExcel, obj, this.$refs, 1, '政务发文监测.xlsx', 1);
    },
    fawenNoTime() {
      this.timeArr = []
      this.selTimeBtn = 1,
        this.$refs.myPop.doClose()
    },
    fawenTimeBtn() {
      this.$refs.myPop.doClose()
      this.search()
    },
    fawenBtnGroup(val) {
      this.selTimeBtn = val;
      if (val == 1) {
        this.timeArr = []
      } else if (val == 2) {
        this.timeArr = [this.$main.timeCycle(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), "yyyy-MM-dd"), this.$main.timeCycle(new Date(new Date()), "yyyy-MM-dd")]
      } else if (val == 3) {
        this.timeArr = [this.$main.timeCycle(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), "yyyy-MM-dd"), this.$main.timeCycle(new Date(new Date()), "yyyy-MM-dd")]
      } else if (val == 4) {
        this.timeArr = [this.$main.timeCycle(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), "yyyy-MM-dd"), this.$main.timeCycle(new Date(new Date()), "yyyy-MM-dd")]
      } else if (val == 5) {
        this.timeArr = [this.$main.timeCycle(new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000), "yyyy-MM-dd"), this.$main.timeCycle(new Date(new Date()), "yyyy-MM-dd")]
      }
    },
    indexMethod(index) {
      return index + this.page.pageNo * this.page.pageSize - this.page.pageSize + 1;
    },
    // 分页
    SizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getList(true);
      this.showFenye = false
      setTimeout(() => {
        this.showFenye = true
      }, 200);
    },
    CurrentChange(val) {
      this.page.pageNo = val;
      this.getList(true);
      this.showFenye = false
      setTimeout(() => {
        this.showFenye = true
      }, 200);
    },
    deleteLastLi() {
      let lastLi = document.querySelector('.el-pager')
      let flagLi = document.querySelector('.btn-quicknext')
      if (lastLi.childNodes.length >= 6&&flagLi) {
        // 删除最后一个节点
        lastLi.removeChild(lastLi.lastChild)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.popover-content {
  padding-top: 20px;
}
.sel-day {
  width: 76px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
}
.sel-day-active {
  background: #fff1e8;
  border: 1px solid #f58030;
}
//表格内容颜色
::v-deep .el-table__body tbody tr:nth-child(odd) {
  background-color: #ffffff;
}
::v-deep .el-table__body tbody tr:nth-child(even) td {
  background-color: #f6f7fa;
}
</style>